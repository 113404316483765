import {pageData} from '../types';

const pages: pageData[] = [
    {
        path: '',
        caption: 'Начало',
        title: 'copytenz.ru',
        showAds: false,
        text: `
                <div className="App">
                <div className="story">
                    <p>
                        Путешествие начинается с востока и заканчивается на западе. Так случайно получилось, что эти
                        заметки я записал не в хронологическом порядке, а в географическом.
                    </p>
                    <p>
                        Я пойду из Сингапура в сторону Лондона, который играл важную роль в моей жизни, хоть я там
                        никогда и не жил.
                    </p>
                    <p>Когда-нибудь я добавлю сюда фотографии, а пока - это просто заметки</p>
                </div>
            </div>
             `,
    },
    {
        path: 'Singapore',
        caption: 'Сингапур',
    },
    {
        path: 'Thailand',
        caption: 'Тайланд',
    },
    {
        path: 'Russia',
        caption: 'Россия',
    },
    {
        path: 'Abkhazia',
        caption: 'Абхазия',
    },
    {
        path: 'Turkey',
        caption: 'Турция',
    },
    {
        path: 'Greece',
        caption: 'Греция',
    },
    {
        path: 'Slovakia',
        caption: 'Словакия',
    },
    {
        path: 'Germany',
        caption: 'Германия',
    },
    {
        path: 'France',
        caption: 'Франция',
    },
    {
        path: 'England',
        caption: 'Англия',
    },
];

export default pages;
