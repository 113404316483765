/******************************
 * Название: web-client-common
 * Описание: Стандартный модуль работы с REST API
 * Автор: copytenz,  ноябрь 2020
 *******************************/

import {settings} from './settings';
import {logError} from './commonFunctions';
import axios from 'axios';
import {AxiosHandler} from './types';
export interface WebQueryResult {
    status: number;
    statusText: string;
    headers?: any;
    config?: any;
    request?: any;
    data?: object[];
}

const isNode = typeof window === 'undefined';
let HTTP_OPTIONS: any;
if (isNode) {
    //node
    let SECRET = '';
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fs = require('fs'); // для чтения настроек с диска
    try {
        SECRET = fs.readFileSync(settings.oAuthFile, 'utf-8').replace(/\n/g, '');
    } catch (err) {
        logError(err, 'WebClient');
        process.exit();
    }
    HTTP_OPTIONS = {
        headers: {
            'Accept-Language': 'ru-RU, ru',
            'Content-Type': 'application/json',
            'User-Agent': 'some-web-client'
        },
    };
    if (SECRET)  HTTP_OPTIONS.headers.Authorization= 'OAuth ' + SECRET;
} else {
    //browser
    HTTP_OPTIONS = {
        mode: 'no-cors',
        withCredentials: true,
        credentials: 'same-origin',
    };
}

export default class WebClient {
    baseURL: string;
    handler: AxiosHandler;
    options: unknown;

    constructor(baseURL: string) {
        this.baseURL = baseURL || '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.handler = axios.create({baseURL: baseURL});
        this.options = HTTP_OPTIONS;
    }

    async get(path: string): Promise<any[]> {
        let result;
        try {
            result = await this.handler.get(path, this.options);
            // console.log(result);
        } catch (err) {
            const statusText = `* ERROR!! Can not GET data on ${path}`;
            console.log(statusText);
            logError(err, 'WebClient get');
            result = {status: 999, statusText};
        }
        return result && result.data ? result.data : [];
    }

    async post(path: string, dataObj: any): Promise<string> {
        let result;
        try {
            result = await this.handler.post(path, dataObj, this.options);
        } catch (err) {
            const statusText = `* ERROR!! Can not PATCH data on ${path}`;
            console.log(`* ERROR!! Can not POST data on ${path}`);
            logError(err, 'WebClient post');
            result = {status: 999, statusText};
        }
        return `${result.status}: ${result.statusText}`;
    }

    async patch(path: string, dataObj: any): Promise<string> {
        let result;
        try {
            result = await this.handler.patch(path, dataObj, this.options);
        } catch (err) {
            const statusText = `* ERROR!! Can not PATCH data on ${path}`;
            console.log(statusText);
            logError(err, 'WebClient patch');
            result = {status: 999, statusText};
        }
        return `${result.status}: ${result.statusText}`;
    }

    async test(path: string): Promise<string> {
        let result: WebQueryResult;
        try {
            result = await this.handler.get(path, this.options);
        } catch (err) {
            const statusText = `* ERROR!! Can not GET data on ${path}`;
            console.log(statusText);
            logError(err, 'WebClient test');
            result = {status: 999, statusText};
        }
        return `${result.status}: ${result.statusText}`;
    }
}
