import React from 'react';

import {pageData} from '../types';
import pages from '../data/pages';
import {Helmet} from 'react-helmet';
import {clear} from 'console';

const IS_MOBILE = /Mobi|Android/i.test(navigator.userAgent);

const adUnit = (path: string) => (IS_MOBILE ? `/ads/${path}-mobile.js` : `/ads/${path}-web.js`);
const clearAdUnits = async (page: pageData) => {
    // const adUnitElements = document.getElementsByClassName('adUnit');
    // adUnitElements
    if (document.querySelectorAll('.adUnit').length > 0) {
        document.querySelectorAll('.adUnit').forEach((element) => {
            // console.log(element);
            if (element.id.indexOf(page.path) < 0) element.parentElement?.removeChild(element);
        });
    }
};

// Adding ads by request

type adPlace = 'top' | 'bottom';

const adPlacer = (path: string, placeBeforeElementId: string = 'mainFooter') => {
    const adUnitID = `adUnit${path}`;
    const adUnitDiv = document.createElement('div');

    const nextDiv = document.getElementById(placeBeforeElementId);

    adUnitDiv.id = adUnitID;
    adUnitDiv.className = 'adUnit';
    nextDiv?.before(adUnitDiv);

    const adScript = document.createElement('script');
    adScript.src = adUnit(path);
    adScript.async = true;

    nextDiv?.before(adScript);
};

const showAdUnit = (page: pageData, place: adPlace = 'bottom') => {
    if (page.showAds) {
        if (place === 'bottom') adPlacer(`${page.path}`, 'mainFooter');
        if (place === 'top') adPlacer(`${page.path}-top`, 'mainArticle');
    }
};

export default function Article(page: pageData = pages[0]) {
    clearAdUnits(page);
    showAdUnit(page, 'top');
    showAdUnit(page, 'bottom');

    return (
        <>
            <Helmet>
                <title>{page.title || page.caption}</title>
            </Helmet>

            <article id="mainArticle" dangerouslySetInnerHTML={{__html: page.text ? page.text : '<br />'}}></article>
        </>
    );
}
