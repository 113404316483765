import React from 'react';
import './css/App.css';

import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom';

import {pageData} from './types';
import pages from './data/pages';
import WebClient from './modules/WebClient';
import NotFound from './components/NotFound';
// import Metrika from './components/Metrika';
import Article from './components/Article';

let BACKEND_ADDRESS = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '/api';
if (navigator.userAgent === 'ReactSnap') BACKEND_ADDRESS = 'https://travel.copytenz.ru/api';

let backendClient = new WebClient(BACKEND_ADDRESS);

const defineCurrentPage = (pagePath: string = ''): pageData => {
    if (!pagePath) pagePath = window.location.pathname;
    let page: pageData = pages.filter(
        (page) => pagePath === `/${page.path}` || window.location.pathname === `/${page.path}.html`,
    )[0];
    if (!page) page = pages[0];
    return page;
};

const defineApiPath = (pagePath: string = ''): string => {
    let apiPath: string = pagePath ? pagePath : window.location.pathname.replace(/.html$/g, '');
    if (apiPath === '/') apiPath = '/index';
    apiPath = `pages/${apiPath}`;
    return apiPath;
};

const showBottomNav = (currentPageIndex: number) => {
    return (
        <div id="bottomNav">
            {currentPageIndex > 0 ? (
                <a href={pages[currentPageIndex - 1].path ? `/${pages[currentPageIndex - 1].path}.html` : `/`}>{`< ${
                    pages[currentPageIndex - 1].caption
                }`}</a>
            ) : (
                ''
            )}
            {currentPageIndex > 0 && currentPageIndex < pages.length - 1 ? ' | ' : ''}
            {currentPageIndex < pages.length - 1 ? (
                <a href={pages[currentPageIndex + 1].path ? `/${pages[currentPageIndex + 1].path}.html` : `/`}>{`${
                    pages[currentPageIndex + 1].caption
                } >`}</a>
            ) : (
                ''
            )}
        </div>
    );
};

// Key logic below

interface Props {
    name?: string;
}

interface State {
    currentPage: pageData;
}

export default class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentPage: defineCurrentPage(),
        };
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.getPageData = this.getPageData.bind(this);
    }

    handleLinkClick() {
        this.setState({currentPage: defineCurrentPage()});
    }

    getPageData(pagePath: string = '') {
        backendClient
            .get(defineApiPath(pagePath))
            .then((res: any) => {
                return res;
            })
            .then((res: any) => {
                let page = res as pageData;
                if (page.text) this.setState({currentPage: page});
                // if (!(page && page.path)) this.setState({currentPage: pages[0]});
            });
        return pages[0];
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            const scrollBar = document.getElementById('scrollBar');
            if (scrollBar)
                scrollBar.style.background =
                    window.pageYOffset > 10 ? 'linear-gradient(to top, #EFEFEF, transparent)' : 'transparent';
            const nav = document.getElementById('topMenu');
            if (nav) nav.style.top = window.pageYOffset < 40 ? `-${window.pageYOffset}px` : `-40px`;
        });
        this.getPageData();
    }

    render() {
        //TODO: заменить a на Link в рутере
        const currentPageIndex =
            this.state.currentPage.path === ''
                ? 0
                : pages.indexOf(pages.filter((page: pageData) => page.path === this.state.currentPage?.path)[0]);
        return (
            <div className="wrapper" id="main">
                {/* {countMetrika()} */}
                <Router>
                    <nav id="topMenu">
                        {pages.map((page: pageData, i) => (
                            <span id={`span-menu-${i}-${page.path}`}>
                                {i ? ' | ' : ''}
                                {page.path ? (
                                    <Link
                                        className={
                                            page.path === pages[currentPageIndex].path
                                                ? 'current-page-link'
                                                : 'app-link'
                                        }
                                        to={`/${page.path}.html`}
                                    >
                                        {i ? i + '.\u00A0' : ''}
                                        {page.caption}
                                    </Link>
                                ) : (
                                    <a
                                        className={
                                            page.path === pages[currentPageIndex].path
                                                ? 'current-page-link'
                                                : 'app-link'
                                        }
                                        href="/"
                                    >
                                        {i ? i + '.\u00A0' : ''}
                                        {page.caption}
                                    </a>
                                )}
                            </span>
                        ))}
                    </nav>
                    <div id="scrollBar"></div>

                    <Switch>
                        <Route exact path="/" render={() => Article(pages[0])} />
                        <Route exact path="/index.html" render={() => Article(pages[0])} />

                        {/* {pages.map((page: pageData) => (
                            <Route exact path={`/${page.path}`} component={Article} />
                        ))} */}
                        {pages.map((page: pageData) => (
                            <Route
                                path={`/${page.path}.html`}
                                render={() =>
                                    Article(
                                        page.path === this.state.currentPage.path
                                            ? this.state.currentPage
                                            : this.getPageData(page.path),
                                    )
                                }
                            />
                        ))}
                        <Route component={NotFound} />
                        <Redirect from="*.js" to="NotFound" />
                    </Switch>
                </Router>
                {showBottomNav(currentPageIndex)}
                <footer id="mainFooter">
                    Данный текст является просто историей путешествия и не несёт каких либо побудительных мотивов.
                    Использование материалов сайта разрешено при размещении прямой ссылки на соответствующую страницу
                    сайта. © copytenz, 2020г.
                </footer>
            </div>
        );
    }
}
