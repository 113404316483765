import React from 'react';
//import ReactDOM from 'react-dom';
// import {render} from 'react-snapshot';
import {hydrate, render} from 'react-dom';

import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//ReactDOM.render(
// render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root'),
// );

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
