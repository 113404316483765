import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

export default function NotFound() {
    return (
        <div>
            <Helmet>
                <title>404 - Not Found</title>
            </Helmet>
            <h1>404 - Not Found!</h1>
            <Link to="/">Go Home</Link>
        </div>
    );
}
