/* eslint-disable no-console */
/******************************
 * Название: commonFunctions
 * Описание: Набор общих функций, чтобы не копировать их в каждом модуле
 * Автор: copytenz,  май 2020
 *******************************/

// Настройки
import {settings} from './settings';

/**
 *  Ищет в тексте вхождение определённого текст по начальному и конечному токену и заменяет его и,
 *  если не найдёт END_TOKEN, всё что ниже(!)
 * @param {string} text Текущий текст всего тикета
 * @param {string} addition Новый текст с иерархической структурой
 * @param {string} startToken Последовательность символов с которой начинается текст
 * @param {string} endToken Последовательность символов характеризующая конец вставляемого текста
 * @returns string
 */
export const updateTextWithAddition = (
    text: string,
    addition: string,
    startToken: string,
    endToken: string,
): string => {
    addition = startToken + addition + endToken;
    text = typeof text === 'string' ? (text = '' + text) : '';
    if (!text.includes(startToken)) return text + addition;
    if (!text.includes(endToken)) return text.substr(0, text.indexOf(startToken)) + addition;
    return (
        text.substr(0, text.indexOf(startToken)) +
        addition +
        text.substr(text.indexOf(endToken) + endToken.length, text.length)
    );
};

export const addSpaces = (n: number): string => {
    let result = '';
    for (let i = 0; i < n; i++) result += ' ';
    return result;
};

export const logDebug = (message: string, level?: number): void => {
    if (settings.debug) console.log(level ? addSpaces(level) + message : message);
};

export const logError = (error: any, where?: string): void => {
    if (typeof error === 'object')
        console.log(`* ERROR in ${where}:\n ${error.name}: ${error.message}\n${error.stack};}`);
    if (typeof error === 'string') console.log(`* ERROR in ${where}:\n ${error}`);
};

export function printObjectProperties(obj: object): void {
    try {
        console.log(JSON.stringify(obj, null, 4));
    } catch (err) {
        logError(err, 'printObjectProperties');
        let result = '';
        for (const propName in obj) {
            // @ts-ignore
            let objValue = obj[propName];
            if (typeof objValue === 'function') {
                objValue = 'function';
            }
            result = result + propName + ': ' + objValue + '\n';
        }
        console.log(result);
    }
}

export const stringContainsOneOf = (sourceString: string, searchValues: string[]): boolean => {
    if (searchValues.length === 0) return true;
    return searchValues.some((value) => sourceString.indexOf(value) > -1);
};

export const stringDoesntContainAnyOf = (sourceString: string, searchValues: string[]): boolean => {
    if (searchValues.length === 0) return true;
    else return searchValues.every((value) => sourceString.indexOf(value) === -1);
};

export const arrayContainsOneOf = (sourceArray: string[], searchValues: string[]): boolean => {
    if (searchValues.length === 0) return false;
    else return searchValues.some((value) => sourceArray.includes(value));
};
